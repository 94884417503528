import { defineComponent, computed } from "@vue/runtime-core";
import { useStore } from 'vuex'
import { StateProps } from '@/store'

import style from './index.module.less'

export default defineComponent({
  setup() {

    const store = useStore<StateProps>()
    const userInfo = computed(() => store.state.userInfo)
    return () => {
      return (
        <div class={style.container}>
          <img src={userInfo.value.header} class={style.header} />
          <div class={style.name}>{userInfo.value.nickname}</div>
        </div>
      )
    }
  }
})