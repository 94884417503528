import { defineComponent } from "@vue/runtime-core"
import { useRouter } from 'vue-router'

import style from './index.module.less'

export default defineComponent({
  setup() {
    const router = useRouter();
    const handleReturn = () => {
      router.go(-1)
    }

    return () => {
      return (
        <div class={style.container}>
          <i onClick={handleReturn} class={`${style.returnImg} iconfont`}>&#xe616;</i>
          <div class={style.title}>个人中心</div>
        </div>
      )
    }
  }
})