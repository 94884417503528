import { defineComponent, PropType, toRefs } from "@vue/runtime-core";
import { Toast } from "vant";
import { useRouter } from 'vue-router'

import style from './index.module.less'

export interface MenuListProps {
  icon: string;
  title: string;
  id: number;
  linkUrl?: string;
}

export default defineComponent({
  props: {
    menuList: {
      type: Array as PropType<MenuListProps[]>,
      required: true
    }
  },
  setup(props) {
    const router = useRouter()
    const { menuList } = toRefs(props)
    const handleToLink = (url: string | undefined) => {
      if (url !== undefined) {
        router.push({ path: url })
      } else {
        Toast('当前功能暂未开放')
      }
    }

    return () => {
      return (
        <div class={style.container}>
          { menuList.value.map(item => {
            const { icon, title, id, linkUrl } = item
            return (
              <div class={style.item} key={id} onClick={() => handleToLink(linkUrl)}>
                <i class={`${style.icon} ${icon} iconfont`}></i>
                <div class={style.title}>{title}</div>
                <i class={`${style.rightIcon} iconfont`}>&#xe601;</i>
              </div>
            )
          })}
        </div>
      )
    }
  }
})