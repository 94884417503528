import { defineComponent, ref } from "@vue/runtime-core";
import { Toast } from "vant";
import { useRouter } from 'vue-router'
import { Dialog } from 'vant';

import style from './index.module.less'

import Header from './components/Header'
import UserInfo from './components/UserInfo'
import Menu, { MenuListProps } from './components/Menu'
import store from "@/store";


export default defineComponent({
  components: {
    Header,
    UserInfo,
  },
  setup() {
    const router = useRouter();
    const menuList = ref<MenuListProps[]>([{
      icon: `iconzhanghao`,
      title: '我的账号',
      id: 1,
      linkUrl: '/account'
    }, {
      icon: `iconshouji`,
      title: '绑定手机',
      id: 2,
      linkUrl: '/bindPhone'
    }, {
      icon: `iconyouxiang_huaban`,
      title: '绑定邮箱',
      id: 3,
    }, {
      icon: `iconshouquan`,
      title: '授权管理',
      id: 4,
    }, {
      icon: `iconwangzhan`,
      title: '我的网站',
      id: 5,
    }, {
      icon: `iconguanggao___`,
      title: '我的广告',
      id: 6,
    }])

    const handleQuit = () => {
      Dialog.confirm({
        title: '是否要退出登录',
        message: '',
      })
      .then(() => {
        store.commit({
          type: 'removeUserInfo',
        })
        setTimeout(() => {
          Toast.success('退出登录')
          router.replace({ path: '/' })
        }, 600)
      })
    }

    return () => {
      return (
        <div class={style.container}>
          <Header />
          <UserInfo />
          <Menu menuList={menuList.value} />
          <van-button onClick={handleQuit} class={style.btn} type="warning">退出登录</van-button>
        </div>
      )
    }
  }
})